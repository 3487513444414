<template>
    <div class="sms-message" v-loading.fullscreen="loading">
        <reminder title="温馨提示：" :text-arr="tips" :hrefConfig="tipsConfig"></reminder>
        <el-card style="margin-bottom: 20px;">
            <div class="flex-col flex-center" style="height: 80px">
                <p style="font-size: 20px;">{{activeCount}}</p>
                <p style="font-size: 14px;">已开启短信通知</p>
            </div>
        </el-card>
        <div class="list-btn" style="margin-bottom: 20px;">
            <el-button type="primary" size="small" @click="openAll(1)" :disabled="loading">一键开启</el-button>
            <el-button type="danger" size="small" @click="openAll(0)" :disabled="loading">一键关闭</el-button>
        </div>
        <el-table :data="tbody" class="thead-light" stripe style="width: 100%">
            <el-table-column type="index" label="序号" :width="80"></el-table-column>
            <el-table-column v-for="(th, thIndex) in thead" :key="th.prop"
                             :label="th.label" show-overflow-tooltip
                             :width="th.width"
                             :min-width="th.minWidth"
                             :sortable="th.sortable">
                <template slot-scope="{row}">
                    {{row[th.prop] | placeholder}}
                </template>
            </el-table-column>
            <el-table-column label="状态" width="150">
                <template slot-scope="{row}">
                    <el-switch v-model="row.status" @change="handleStatusChange(row.id, row.status)" :active-value="1" :inactive-value="0"></el-switch>
                    <el-popover placement="left" title="" width="280" :visible-arrow="false" trigger="hover">
                        <table class="popover-table">
                            <tr>
                                <td style="padding-bottom: 20px" class="td-title">模板类型</td>
                                <td>{{row.type_text}}</td>
                            </tr>
                            <tr>
                                <td style="padding-bottom: 20px" class="td-title">模板名称</td>
                                <td>{{row.name}}</td>
                            </tr>
                            <tr>
                                <td style="padding-bottom: 20px" class="td-title">模板编号</td>
                                <td>{{row.template_code}}</td>
                            </tr>
                            <tr>
                                <td style="padding-bottom: 20px" class="td-title">模板内容</td>
                                <td>{{row.content}}</td>
                            </tr>
                        </table>
                        <el-button style="margin-left: 20px;" type="text" slot="reference">详情</el-button>
                    </el-popover>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import Reminder from "../../common/components/Reminder";
import PopoverInputEdit from "../../../base/components/Popover/PopoverInputEdit";
import {
    getSmsList,
    changeStatus,
    changeAllStatus,
} from "../api/sms-list";

export default {
    components: {PopoverInputEdit, Reminder},
    data() {
        return {
            loading: true,
            activeCount: 0,
            tips: [
                "关闭后将不发送该短信通知",
            ],
            tipsConfig: {
            },
            thead: [
                {label: "模板名称", prop: "name", minWidth: 100},
                // {label: "模板CODE", prop: "template_code", minWidth: 200},
                {label: "模板类型", prop: "type_text", minWidth: 100},
            ],
            tbody: [],
        };
    },
    computed: {
        /* 是全部开启的 */
        isAllOpened() {
            return this.tbody.filter(el => el.status === 1).length === this.tbody.length;
        },
        /* 是全部关闭的 */
        isAllClosed() {
            return this.tbody.filter(el => el.status === 0).length === this.tbody.length;
        }
    },
    created() {
        this.getList();
    },
    methods: {
        /**
         * 获取列表数据
         */
        getList() {
            this.loading = true;
            getSmsList()
                .then((res) => {
                    this.tbody = res.data.data;
                    this.activeCount = this.tbody.filter((it) => it.status === 1).length;
                })
                .catch((err) => {
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        /**
         * 点击一键开启/关闭
         * @param{number} status  1: 设为开启， 0: 设为关闭
         */
        openAll(status = 1) {
            this.loading = true;
            changeAllStatus(status)
                .then((res) => {
                    this.$message.success(res.msg);
                })
                .finally(()=>{
                    this.getList();
                });
        },
        handleStatusChange(id, status) {
            this.loading = true;
            changeStatus(id, status)
                .then((res) => {
                    this.$message.success(res.msg);
                })
                .finally(()=>{
                    this.getList();
                });
        },
    },
};
</script>

<style lang="scss">
.sms-message {
    .el-card {
        width: 390px;
    }
}

.popover-table {
    .td-title {
        white-space: nowrap;
        width: 75px;
    }

    td {
        vertical-align: top;
        padding: 10px 0;
    }
}

.popover-detail-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    line-height: 14px;
    padding: 10px 0;
    /*cursor: pointer;*/
    cursor: not-allowed;
    margin-top: 40px;

    &:hover {
        opacity: 0.7;
    }
}
</style>
