import api from '@/base/utils/request';

export const getSmsList = () => {
    return api({
        url: '/admin/admin/sms_message/index',
        method: 'post'
    })
}

export const changeStatus = (id, status) => {
    return api({
        url: '/admin/admin/sms_message/changeStatus',
        method: 'post',
        data: {id, status},
        notCancel: true
    })
}

export const changeAllStatus = (status) => {
    return api({
        url: '/admin/admin/sms_message/changeAllStatus',
        method: 'post',
        data: {status},
        notCancel: true
    })
}
